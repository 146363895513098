import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
  Typography,
  IconButton,
  Container,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { FormattedMessage, useIntl, FormattedDate } from "react-intl";
import { Attendee } from "../../../redux/types/types";
import { useDispatch } from "react-redux";
import { AppDispatch, RootState } from "../../../redux/store";
import { enrollWaitingAttendee } from "../../../redux/enrollment/enrollmentOperations";
import { getProfile } from "../../../redux/auth/authOperations";
import * as XLSX from "xlsx";
import { useSelector } from "react-redux";

interface WaitingListModalProps {
  open: boolean;
  onClose: () => void;
  waitingAttendees: Attendee[];
  classId?: number | null;
}

export default function WaitingListModal({
  open,
  onClose,
  waitingAttendees,
  classId,
}: WaitingListModalProps) {
  const dispatch = useDispatch<AppDispatch>();
  const intl = useIntl();
  const isAdmin = useSelector((state: RootState) => state.auth.isAdmin);
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );
  const classDataAdmin = useSelector((state: RootState) =>
    state.admin.classes.find((c) => c.id === classId)
  );
  const classDataInstructor = useSelector((state: RootState) =>
    state.auth.user.classes.find((c) => c.id === classId)
  );

  const [attendees, setAttendees] = useState<Attendee[]>([]);

  const handleEnrollClick = async (
    waitingAttendeeId: number,
    attendeeId: number
  ) => {
    await dispatch(
      enrollWaitingAttendee({ waitingAttendeeId, language: intl.locale })
    );
    await setAttendees((prev) => prev.filter((a) => a.id !== attendeeId));
    await dispatch(getProfile());
  };

  const calculateAge = (birthdate: Date): number => {
    const today = new Date();
    const birthDate = new Date(birthdate);
    let age = today.getFullYear() - birthDate.getFullYear();
    const m = today.getMonth() - birthDate.getMonth();
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const exportToExcel = () => {
    const classData = isAdmin ? classDataAdmin : classDataInstructor;

    if (classData) {
      const worksheet = XLSX.utils.json_to_sheet(
        attendees.map((attendee) => ({
          "User First Name": attendee.user?.firstname || "",
          "User Last Name": attendee.user?.lastname || "",
          "User Email": attendee.user?.email || "",
          "Attendee First Name": attendee.firstName,
          "Attendee Last Name": attendee.lastName,
          Age: calculateAge(attendee.birthdate),
          Sex:
            attendee.sex === "female"
              ? intl.formatMessage({ id: "privateEnroll.female" })
              : attendee.sex === "male"
              ? intl.formatMessage({ id: "privateEnroll.male" })
              : attendee.sex === "other"
              ? intl.formatMessage({ id: "privateEnroll.other" })
              : attendee.sex,
          Email: attendee.email,
          Phone: attendee.phone,
          "Postal Code": attendee.postalCode || "",
          "Permis Status":
            attendee.permisStatus === "PermisS"
              ? intl.formatMessage({
                  id: "attendee.permisStatus.permisS",
                })
              : intl.formatMessage({ id: "attendee.permisStatus.other" }),
          "Enrollment Date": attendee.createdAt
            ? intl.formatDate(attendee.createdAt, {
                year: "numeric",
                month: "long",
                day: "numeric",
              })
            : "",
          "Can Enroll": attendee.canEnroll ? "Yes" : "No",
          "Class Title": classData.title.fr || classData.title.en,
          "Instructor Name": `${classData.instructors
            .map((i) => `${i.instructor.firstname} ${i.instructor.lastname}`)
            .join(", ")}`,
          Location: classData.location.name.fr || classData.location.name.en,
          Type: classData.type.name.fr || classData.type.name.en,
        }))
      );

      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Waiting List");

      XLSX.writeFile(workbook, `Waiting_List_${new Date().toISOString()}.xlsx`);
    }
  };

  useEffect(() => {
    setAttendees(waitingAttendees);
  }, [waitingAttendees, dispatch]);

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle
        sx={{
          m: 0,
          p: 2,
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <FormattedMessage
          id="myClasses.waitingList"
          defaultMessage="Waiting List"
        />
        <div>
          {(isAdmin || isInstructor) && (
            <Button onClick={exportToExcel} sx={{ mr: 1 }}>
              <FormattedMessage
                id="admin.dashboard.reports.exportExcel"
                defaultMessage="Export to Excel"
              />
            </Button>
          )}
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </div>
      </DialogTitle>
      <DialogContent>
        <List>
          {attendees.map((attendee) => (
            <ListItem key={attendee.id} divider alignItems="flex-start">
              <Container style={{ paddingLeft: 0 }}>
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span">
                      {intl.formatMessage({
                        id: "waitingList.user",
                        defaultMessage: "User",
                      })}
                      {": "}
                      {`${attendee.user?.firstname} ${attendee.user?.lastname}`}
                    </Typography>
                  }
                />
                <ListItemText
                  primary={
                    <Typography variant="subtitle1" component="span">
                      {intl.formatMessage({
                        id: "waitingList.attendee",
                        defaultMessage: "Attendee",
                      })}
                      {": "}
                      {`${attendee.firstName} ${attendee.lastName}`}
                    </Typography>
                  }
                  secondary={
                    <React.Fragment>
                      <Typography
                        variant="body2"
                        component="span"
                        display="block"
                      >
                        {intl.formatMessage({
                          id: "waitingList.enrollmentDate",
                          defaultMessage: "Enrollment date",
                        })}
                        {": "}
                        {attendee.createdAt && (
                          <FormattedDate
                            value={attendee.createdAt}
                            year="numeric"
                            month="long"
                            day="numeric"
                          />
                        )}
                      </Typography>
                    </React.Fragment>
                  }
                />
              </Container>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  if (attendee.waitingAttendeeId) {
                    handleEnrollClick(attendee.waitingAttendeeId, attendee.id);
                  }
                }}
                disabled={!attendee.canEnroll}
                sx={{ mt: 1 }}
              >
                <FormattedMessage
                  id="waitingList.enroll"
                  defaultMessage="Enroll"
                />
              </Button>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
