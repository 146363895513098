import React from "react";
import { useIntl } from "react-intl";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Box,
} from "@mui/material";
import { FormattedMessage } from "react-intl";
import { Schedule } from "../../../../redux/types/types";
import { format } from "date-fns";

interface ScheduleListProps {
  schedules: Schedule[];
  classStartDate: Date | null | undefined;
}

const ScheduleList: React.FC<ScheduleListProps> = ({
  schedules,
  classStartDate,
}) => {
  const intl = useIntl();

  const daysOfWeek = [
    intl.formatMessage({ id: "admin.dashboard.monday" }),
    intl.formatMessage({ id: "admin.dashboard.tuesday" }),
    intl.formatMessage({ id: "admin.dashboard.wednesday" }),
    intl.formatMessage({ id: "admin.dashboard.thursday" }),
    intl.formatMessage({ id: "admin.dashboard.friday" }),
    intl.formatMessage({ id: "admin.dashboard.saturday" }),
    intl.formatMessage({ id: "admin.dashboard.sunday" }),
  ];

  const weeklySchedules =
    schedules?.filter((schedule) => schedule.repeatWeekly) || [];
  const nonWeeklySchedules =
    schedules?.filter((schedule) => {
      // if (classStartDate && schedule.date) {
      //   return !schedule.repeatWeekly && schedule.date > classStartDate;
      // }

      return !schedule.repeatWeekly;
    }) || [];

  const sortAndGroupSchedules = (
    scheduleList: Schedule[],
    isWeekly: boolean
  ) => {
    const sortedSchedules = [...scheduleList].sort((a, b) => {
      if (isWeekly) {
        if (a.dayOfWeek !== b.dayOfWeek) {
          return a.dayOfWeek - b.dayOfWeek;
        }
      } else {
        const dateA = new Date(a.date!);
        const dateB = new Date(b.date!);
        if (dateA.getTime() !== dateB.getTime()) {
          return dateA.getTime() - dateB.getTime();
        }
      }
      return a.startTime.localeCompare(b.startTime);
    });

    return sortedSchedules.reduce((acc, schedule) => {
      const key = isWeekly
        ? schedule.dayOfWeek
        : format(new Date(schedule.date!), "yyyy-MM-dd");
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(schedule);
      return acc;
    }, {} as Record<number | string, Schedule[]>);
  };

  const renderScheduleTable = (
    groupedSchedules: Record<number | string, Schedule[]>,
    isWeekly: boolean
  ) => (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell>
              {isWeekly ? (
                <FormattedMessage id="admin.dashboard.dayOfWeek" />
              ) : (
                <FormattedMessage id="admin.dashboard.date" />
              )}
            </TableCell>
            <TableCell>
              <FormattedMessage id="admin.dashboard.startTime" />
            </TableCell>
            <TableCell>
              <FormattedMessage id="admin.dashboard.endTime" />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(groupedSchedules).map(([key, daySchedules]) => (
            <React.Fragment key={key}>
              {daySchedules.map((schedule, index) => (
                <TableRow key={schedule.id}>
                  {index === 0 && (
                    <TableCell rowSpan={daySchedules.length}>
                      {isWeekly
                        ? daysOfWeek[parseInt(key)]
                        : intl.formatDate(format(new Date(key), "MMM d, yyyy"))}
                    </TableCell>
                  )}
                  <TableCell>{schedule.startTime}</TableCell>
                  <TableCell>{schedule.endTime}</TableCell>
                </TableRow>
              ))}
            </React.Fragment>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const groupedWeeklySchedules = sortAndGroupSchedules(weeklySchedules, true);
  const groupedNonWeeklySchedules = sortAndGroupSchedules(
    nonWeeklySchedules,
    false
  );

  return (
    <Box sx={{ mt: 2 }}>
      <Typography variant="h6" gutterBottom>
        <FormattedMessage id="admin.dashboard.schedules" />
      </Typography>

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        <FormattedMessage id="admin.dashboard.weeklySchedules" />
      </Typography>
      {weeklySchedules.length > 0 ? (
        renderScheduleTable(groupedWeeklySchedules, true)
      ) : (
        <Typography variant="body2">
          <FormattedMessage id="admin.dashboard.noWeeklySchedules" />
        </Typography>
      )}

      <Typography variant="h6" gutterBottom sx={{ mt: 4 }}>
        <FormattedMessage id="admin.dashboard.nonWeeklySchedules" />
      </Typography>
      {nonWeeklySchedules.length > 0 ? (
        renderScheduleTable(groupedNonWeeklySchedules, false)
      ) : (
        <Typography variant="body2">
          <FormattedMessage id="admin.dashboard.noNonWeeklySchedules" />
        </Typography>
      )}
    </Box>
  );
};

export default ScheduleList;
