import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  Box,
  Typography,
  Card,
  CardContent,
  Grid,
  useTheme,
  useMediaQuery,
  Button,
  IconButton,
  Popover,
} from "@mui/material";
import { FormattedMessage, useIntl } from "react-intl";
import {
  PeopleAlt as PeopleAltIcon,
  Group as GroupIcon,
} from "@mui/icons-material";
import { RootState } from "../../redux/store";
import { Class, Attendee } from "../../redux/types/types";
import { useLanguage } from "../../components/LanguageProvider/LanguageProvider";
import EnrollmentModal from "./EnrollmentModal/EnrollmentModal";
import WaitingListModal from "./WaitingListModal/WaitingListModal";
import AttendeesList from "./AttendeesList/AttendeesList";
import AttendeesListModal from "../AdminDashboard/ClassManagement/ClassesList/AttendeesListModal/AttendeesListModal";

export default function MyClasses() {
  const intl = useIntl();
  const user = useSelector((state: RootState) => state.auth.user);
  const isInstructor = useSelector(
    (state: RootState) => state.auth.isInstructor
  );
  const { language } = useLanguage();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const [selectedClass, setSelectedClass] = useState<Class | null>(null);
  const [selectedClassId, setSelectedClassId] = useState<number | null>(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isWaitingListModalOpen, setIsWaitingListModalOpen] = useState(false);
  const [selectedWaitingAttendees, setSelectedWaitingAttendees] = useState<
    Attendee[]
  >([]);
  const [attendeesAnchorEl, setAttendeesAnchorEl] =
    useState<HTMLButtonElement | null>(null);
  const [attendees, setAttendees] = useState<Attendee[]>([]);

  const getLocalizedValue = (
    obj: Record<string, string>,
    lang: string
  ): string => {
    return obj[lang] || obj["en"] || "";
  };

  const handleEnrollClick = (classItem: Class) => {
    setSelectedClass(classItem);
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleWaitingListClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    attendees: Attendee[],
    classId: number
  ) => {
    setSelectedWaitingAttendees(attendees);
    setIsWaitingListModalOpen(true);
    setSelectedClassId(classId);
  };

  const handleAttendeesClick = (
    event: React.MouseEvent<HTMLButtonElement>,
    attendees: Attendee[],
    classId: number,
    selectedClass: Class
  ) => {
    const enrolledAttendees = attendees.filter(
      (attendee) => !attendee.unenrollDate
    );
    setAttendeesAnchorEl(event.currentTarget);
    setAttendees(enrolledAttendees);
    setSelectedClassId(classId);
    setSelectedClass(selectedClass);
  };

  const handleWaitingListClose = () => {
    setIsWaitingListModalOpen(false);
    setSelectedClassId(null);
  };

  const handleAttendeesClose = () => {
    setAttendeesAnchorEl(null);
    setSelectedClassId(null);
    setSelectedClass(null);
  };

  const handleAttendeeUnenroll = async (updatedAttendees: Attendee[]) => {
    await setAttendees(updatedAttendees);
  };

  const attendeesOpen = Boolean(attendeesAnchorEl);
  const attendeesId = attendeesOpen ? "attendees-list-popover" : undefined;

  const renderClassCard = (classItem: Class) => {
    const enrolledAttendees =
      classItem.attendees?.filter((attendee) => !attendee.unenrollDate) || [];

    return (
      <Card
        sx={{
          height: "100%",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <CardContent sx={{ flex: 1 }}>
          <Typography variant="h6" component="div">
            {getLocalizedValue(classItem.title, language) || classItem.title.fr}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: "myClasses.instructor",
              defaultMessage: "Instructor",
            })}
            :{" "}
            {classItem.instructors.map(
              (i) => `${i.instructor.firstname} ${i.instructor.lastname} `
            )}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: "myClasses.type",
              defaultMessage: "Type",
            })}
            : {getLocalizedValue(classItem.type?.name, language)}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            {intl.formatMessage({
              id: "myClasses.location",
              defaultMessage: "Location",
            })}
            : {getLocalizedValue(classItem.location?.name, language)}
          </Typography>
        </CardContent>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            p: 2,
            borderLeft: "1px solid",
            borderColor: "divider",
          }}
        >
          {isInstructor && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleEnrollClick(classItem)}
              sx={{ mb: 2 }}
            >
              <FormattedMessage
                id="myClasses.enrollAttendee"
                defaultMessage="Enroll Attendee"
              />
            </Button>
          )}
          <IconButton //
            color="success"
            onClick={(event) =>
              handleAttendeesClick(
                event,
                enrolledAttendees,
                classItem.id,
                classItem
              )
            }
            size="small"
            disabled={enrolledAttendees.length === 0}
            sx={{ mb: 2 }}
          >
            {isInstructor && (
              <Typography sx={{ mr: 1 }}>
                {intl.formatMessage({
                  id: "myClasses.attendeesList",
                  defaultMessage: "Attendees List",
                })}
              </Typography>
            )}
            <GroupIcon />
            {(isInstructor || enrolledAttendees.length > 0) && (
              <Typography
                variant="caption"
                component="span"
                sx={{
                  position: "absolute",
                  top: -4,
                  right:
                    language === "en" && isInstructor
                      ? 1
                      : language === "fr" && isInstructor
                      ? 1
                      : language === "uk" && isInstructor
                      ? 20
                      : -4,
                  backgroundColor: "primary.main",
                  color: "primary.contrastText",
                  borderRadius: "50%",
                  width:
                    16 *
                    (enrolledAttendees.length.toString().length === 1
                      ? enrolledAttendees.length.toString().length
                      : enrolledAttendees.length.toString().length / 2),
                  height: 16,
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: "0.75rem",
                }}
              >
                {enrolledAttendees.length}
              </Typography>
            )}
          </IconButton>
          {isInstructor && (
            <IconButton
              color="default"
              onClick={(event) =>
                handleWaitingListClick(
                  event,
                  classItem.waitingAttendees || [],
                  classItem.id
                )
              }
              size="small"
              disabled={
                !classItem.waitingAttendees ||
                classItem.waitingAttendees.length === 0
              }
            >
              <Typography sx={{ mr: 1 }}>
                {intl.formatMessage({
                  id: "myClasses.waitingList",
                  defaultMessage: "Waiting List",
                })}
              </Typography>
              <PeopleAltIcon />
              {classItem.waitingAttendees && (
                <Typography
                  variant="caption"
                  component="span"
                  sx={{
                    position: "absolute",
                    top: -4,
                    right:
                      language === "en"
                        ? 10
                        : language === "fr"
                        ? 25
                        : language === "uk"
                        ? 13
                        : -4,
                    backgroundColor: "primary.main",
                    color: "primary.contrastText",
                    borderRadius: "50%",
                    width:
                      16 *
                      (classItem.waitingAttendees.length.toString().length === 1
                        ? classItem.waitingAttendees.length.toString().length
                        : classItem.waitingAttendees.length.toString().length /
                          2),
                    height: 16,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    fontSize: "0.75rem",
                  }}
                >
                  {classItem.waitingAttendees.length}
                </Typography>
              )}
            </IconButton>
          )}
        </Box>
      </Card>
    );
  };

  return (
    <Box
      sx={{
        my: 4,
        mx: 2,
        width: "100%",
        maxWidth: "1200px",
        margin: "0 auto",
        pt: isMobile ? 5 : 0,
      }}
    >
      {user.classes.length === 0 ? (
        <Typography>
          <FormattedMessage
            id="myClasses.noClasses"
            defaultMessage="You are not enrolled in any classes."
          />
        </Typography>
      ) : (
        <Grid container spacing={2}>
          {user.classes
            .filter((classItem) => classItem.status !== "Cancelled")
            .filter((classItem) => {
              if (isInstructor) {
                // Instructors see all classes (except cancelled ones)
                return true;
              } else {
                // Regular users see classes with enrolled attendees
                const enrolledAttendees =
                  classItem.attendees?.filter(
                    (attendee) => !attendee.unenrollDate
                  ) || [];
                return enrolledAttendees.length > 0;
              }
            })
            .map((classItem) => (
              <Grid item xs={12} md={6} key={classItem.id}>
                {renderClassCard(classItem)}
              </Grid>
            ))}
        </Grid>
      )}
      <EnrollmentModal
        open={isModalOpen}
        modalClose={handleModalClose}
        onClose={() => setIsModalOpen(false)}
        classItem={selectedClass}
      />
      <WaitingListModal
        open={isWaitingListModalOpen}
        onClose={handleWaitingListClose}
        waitingAttendees={selectedWaitingAttendees}
        classId={selectedClassId}
      />
      <Popover
        id={attendeesId}
        open={attendeesOpen && !isInstructor}
        anchorEl={attendeesAnchorEl}
        onClose={handleAttendeesClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        PaperProps={{
          sx: {
            maxHeight: "calc(100vh - 32px)",
            maxWidth: "calc(100vw - 32px)",
            overflowY: "auto",
          },
        }}
        disableScrollLock
      >
        <AttendeesList
          onClose={handleAttendeesClose}
          attendeesList={attendees}
          classId={selectedClassId || 0}
          schedulesList={selectedClass?.schedules || []}
        />
      </Popover>

      <AttendeesListModal
        open={Boolean(isInstructor && attendeesOpen)}
        onClose={handleAttendeesClose}
        attendees={attendees}
        classId={selectedClassId || 0}
        schedulesList={selectedClass?.schedules || []}
        onAttendeeUnenroll={handleAttendeeUnenroll}
      />
    </Box>
  );
}
