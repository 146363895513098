import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../../../redux/store";
import {
  fetchSchedules,
  bulkCreateSchedules,
  bulkUpdateSchedules,
  bulkDeleteSchedules,
} from "../../../../redux/admin/adminOperations";
import {
  Box,
  Typography,
  IconButton,
  Button,
  Paper,
  Grid,
  Toolbar,
  AppBar,
  TextField,
  useMediaQuery,
  useTheme,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  List,
  ListItem,
  ListItemText,
  Tabs,
  Tab,
} from "@mui/material";
import {
  Add as AddIcon,
  Delete as DeleteIcon,
  ExpandMore as ExpandMoreIcon,
} from "@mui/icons-material";
import { Notify } from "notiflix/build/notiflix-notify-aio";
import { FormattedMessage, useIntl } from "react-intl";
import { Schedule } from "../../../../redux/types/types";

interface LocalSchedule extends Schedule {
  isNew?: boolean;
  isEditing?: boolean;
  isDirty?: boolean;
  isDeleted?: boolean;
  startTimeErrorMessage?: string;
  endTimeErrorMessage?: string;
  startTimeFocused?: boolean;
  endTimeFocused?: boolean;
}

interface ScheduleManagerProps {
  classId: number;
}

const daysOfWeek = [
  "monday",
  "tuesday",
  "wednesday",
  "thursday",
  "friday",
  "saturday",
  "sunday",
];

export default function ScheduleManager({ classId }: ScheduleManagerProps) {
  const dispatch = useDispatch<AppDispatch>();
  const [localSchedules, setLocalSchedules] = useState<LocalSchedule[]>([]);
  const [selectedScheduleId, setSelectedScheduleId] = useState<number | null>(
    null
  );
  const [activeTab, setActiveTab] = useState(0);
  const intl = useIntl();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    const newSchedules = localSchedules.filter((s) => s.isNew);
    if (newSchedules.length) {
      localStorage.setItem("newClassSchedules", JSON.stringify(newSchedules));
    }
  }, [localSchedules]);

  useEffect(() => {
    loadSchedules();
    //eslint-disable-next-line
  }, [dispatch, classId, intl]);

  const loadSchedules = async () => {
    try {
      const fetchedSchedules = await dispatch(
        fetchSchedules({
          classId,
          page: 1,
          limit: 100,
          sortBy: "dayOfWeek",
          sortOrder: "asc",
        })
      ).unwrap();

      const savedSchedules = await localStorage.getItem("newClassSchedules");
      if (savedSchedules?.length) {
        await setLocalSchedules([
          ...fetchedSchedules.schedules.map((schedule) => ({
            ...schedule,
            id: Number(schedule.id),
            isDirty: false,
            isEditing: false,
            isDeleted: false,
            startTimeFocused: false,
            endTimeFocused: false,
          })),
          ...JSON.parse(savedSchedules),
        ]);
      } else {
        await setLocalSchedules(
          fetchedSchedules.schedules.map((schedule) => ({
            ...schedule,
            id: Number(schedule.id),
            isDirty: false,
            isEditing: false,
            isDeleted: false,
            startTimeFocused: false,
            endTimeFocused: false,
          }))
        );
      }
      Notify.success(
        intl.formatMessage({ id: "admin.dashboard.schedulesLoadedSuccess" })
      );
    } catch (error) {
      console.error("Failed to fetch schedules:", error);
      Notify.failure(
        intl.formatMessage({ id: "admin.dashboard.schedulesLoadedError" })
      );
    }
  };

  const handleAddSchedule = (dayOfWeek: number, repeatWeekly: boolean) => {
    const newSchedule: LocalSchedule = {
      id: Date.now(),
      dayOfWeek,
      startTime: "",
      endTime: "",
      repeatWeekly,
      classId,
      isNew: true,
      isEditing: true,
      isDirty: true,
      isDeleted: false,
      date: repeatWeekly ? null : new Date(),
      startTimeErrorMessage: "",
      endTimeErrorMessage: "",
      startTimeFocused: false,
      endTimeFocused: false,
    };
    setLocalSchedules([...localSchedules, newSchedule]);
  };

  const handleUpdateSchedule = (updatedSchedule: LocalSchedule) => {
    const updatedSchedules = localSchedules.map((s) =>
      s.id === updatedSchedule.id ? { ...updatedSchedule, isDirty: true } : s
    );
    setLocalSchedules(updatedSchedules);
  };

  const handleDeleteSchedule = (scheduleId: number) => {
    const updatedSchedules = localSchedules.map((s) =>
      s.id === scheduleId ? { ...s, isDeleted: true, isDirty: true } : s
    );
    setLocalSchedules(updatedSchedules);
    if (selectedScheduleId === scheduleId) {
      setSelectedScheduleId(null);
    }
  };

  const handleFocusChange = (
    schedule: LocalSchedule,
    field: "startTimeFocused" | "endTimeFocused",
    isFocused: boolean
  ) => {
    const updatedSchedule = {
      ...schedule,
      [field]: isFocused,
    };
    setLocalSchedules((prevSchedules) =>
      prevSchedules.map((s) =>
        s.id === updatedSchedule.id ? updatedSchedule : s
      )
    );
  };

  const hasErrors = localSchedules.some(
    (schedule) =>
      (!!schedule.startTimeErrorMessage && !schedule.startTimeFocused) ||
      (!!schedule.endTimeErrorMessage && !schedule.endTimeFocused)
  );

  const handleSaveAllChanges = async () => {
    const schedulesToSave = localSchedules.filter((s) => !s.isDeleted);
    const schedulesToCreate = schedulesToSave.filter((s) => s.isNew);
    const schedulesToUpdate = schedulesToSave.filter(
      (s) => s.isDirty && !s.isNew
    );
    const schedulesToDelete = localSchedules.filter((s) => s.isDeleted);

    try {
      if (schedulesToDelete.length > 0) {
        await dispatch(
          bulkDeleteSchedules(schedulesToDelete.map((s) => s.id))
        ).unwrap();
      }
      if (schedulesToUpdate.length > 0) {
        await dispatch(bulkUpdateSchedules(schedulesToUpdate)).unwrap();
      }
      if (schedulesToCreate.length > 0) {
        await dispatch(bulkCreateSchedules(schedulesToCreate)).unwrap();
      }

      Notify.success(
        intl.formatMessage({ id: "admin.dashboard.schedulesSavedSuccess" })
      );
      await localStorage.removeItem("newClassSchedules");
      await loadSchedules();
    } catch (error) {
      console.error("Failed to save schedules:", error);
      Notify.failure(
        intl.formatMessage({ id: "admin.dashboard.schedulesSavedError" })
      );
    }
  };

  const handleTimeChange = (
    schedule: LocalSchedule,
    field: "startTime" | "endTime",
    value: string
  ) => {
    const updatedSchedule = {
      ...schedule,
      [field]: value,
      isEditing: false,
    };

    const timeFormat = /^([01]\d|2[0-3]):([0-5]\d)$/;
    const isValidFormat = timeFormat.test(value);
    let errorMessage = "";

    if (!isValidFormat) {
      errorMessage = intl.formatMessage({
        id: "admin.dashboard.invalidTimeFormatError",
      });
    }

    if (field === "startTime") {
      updatedSchedule.startTimeErrorMessage = errorMessage;
    } else {
      updatedSchedule.endTimeErrorMessage = errorMessage;
    }

    const startTimeStr = updatedSchedule.startTime;
    const endTimeStr = updatedSchedule.endTime;

    const startTimeValid = timeFormat.test(startTimeStr);
    const endTimeValid = timeFormat.test(endTimeStr);

    if (startTimeValid && endTimeValid) {
      const startMinutes = timeToMinutes(startTimeStr);
      const endMinutes = timeToMinutes(endTimeStr);

      if (startMinutes >= endMinutes) {
        const invalidTimeErrorMessage = intl.formatMessage({
          id: "admin.dashboard.invalidTimeError",
        });
        if (field === "startTime") {
          updatedSchedule.startTimeErrorMessage = invalidTimeErrorMessage;
        } else {
          updatedSchedule.endTimeErrorMessage = invalidTimeErrorMessage;
        }
      } else {
        updatedSchedule.startTimeErrorMessage = "";
        updatedSchedule.endTimeErrorMessage = "";
      }
    }

    handleUpdateSchedule(updatedSchedule);
  };

  function timeToMinutes(timeStr: string): number {
    const [hoursStr, minutesStr] = timeStr.split(":");
    const hours = parseInt(hoursStr, 10);
    const minutes = parseInt(minutesStr, 10);
    return hours * 60 + minutes;
  }

  const handleDateChange = (schedule: LocalSchedule, value: string) => {
    const selectedDate = value.length ? new Date(value) : new Date();
    const dayOfWeek = selectedDate.getDay();
    const adjustedDayOfWeek = dayOfWeek === 0 ? 6 : dayOfWeek - 1;

    const updatedSchedule = {
      ...schedule,
      date: selectedDate,
      dayOfWeek: adjustedDayOfWeek,
      isEditing: false,
    };
    handleUpdateSchedule(updatedSchedule);
  };

  const handleAccordionChange = async (schedule: any) => {
    if (selectedScheduleId !== schedule.id && !schedule.isNew) {
      setSelectedScheduleId(schedule.id);
    } else {
      setSelectedScheduleId(null);
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const renderSchedules = (repeatWeekly: boolean) => {
    return (
      <Grid container spacing={2}>
        {repeatWeekly ? (
          daysOfWeek.map((day, index) => (
            <Grid item xs={12} key={day}>
              <Paper sx={{ p: 2 }}>
                <Box
                  display="flex"
                  flexDirection={isMobile ? "column" : "row"}
                  justifyContent="space-between"
                  alignItems={isMobile ? "flex-start" : "center"}
                  mb={2}
                >
                  <Typography variant="h6" sx={{ mb: isMobile ? 1 : 0 }}>
                    <FormattedMessage id={`admin.dashboard.${day}`} />
                  </Typography>
                  <IconButton
                    onClick={() => handleAddSchedule(index, true)}
                    color="primary"
                    sx={{ alignSelf: isMobile ? "flex-end" : "center" }}
                  >
                    <AddIcon />
                  </IconButton>
                </Box>
                {renderScheduleList(index, true)}
              </Paper>
            </Grid>
          ))
        ) : (
          <Grid item xs={12}>
            <Paper sx={{ p: 2 }}>
              <Box
                display="flex"
                flexDirection={isMobile ? "column" : "row"}
                justifyContent="space-between"
                alignItems={isMobile ? "flex-start" : "center"}
                mb={2}
              >
                <Typography variant="h6" sx={{ mb: isMobile ? 1 : 0 }}>
                  <FormattedMessage id="admin.dashboard.oneTimeSchedules" />
                </Typography>
                <IconButton
                  onClick={() => handleAddSchedule(0, false)}
                  color="primary"
                  sx={{ alignSelf: isMobile ? "flex-end" : "center" }}
                >
                  <AddIcon />
                </IconButton>
              </Box>
              {renderScheduleList(null, false)}
            </Paper>
          </Grid>
        )}
      </Grid>
    );
  };

  const renderScheduleList = (
    dayOfWeek: number | null,
    repeatWeekly: boolean
  ) => {
    const filteredSchedules = localSchedules.filter(
      (s) =>
        !s.isDeleted &&
        s.repeatWeekly === repeatWeekly &&
        (dayOfWeek === null || s.dayOfWeek === dayOfWeek)
    );

    return filteredSchedules.length > 0 ? (
      filteredSchedules.map((schedule) => (
        <Box key={schedule.id} sx={{ mb: 2 }}>
          <Box
            display="flex"
            flexDirection={isMobile ? "column" : "row"}
            alignItems="center"
          >
            {!repeatWeekly && (
              <TextField
                label={intl.formatMessage({ id: "admin.dashboard.date" })}
                type="date"
                value={
                  schedule.date
                    ? new Date(schedule.date).toISOString().split("T")[0]
                    : ""
                }
                onChange={(e) => handleDateChange(schedule, e.target.value)}
                InputLabelProps={{ shrink: true }}
                inputProps={{
                  min: new Date().toISOString().split("T")[0],
                }}
                sx={{
                  mr: 2,
                  mb: isMobile ? 1 : 0,
                  width: isMobile ? "100%" : "auto",
                }}
              />
            )}
            <TextField
              label={intl.formatMessage({ id: "admin.dashboard.startTime" })}
              type="text"
              placeholder="HH:mm"
              value={schedule.startTime}
              onChange={(e) =>
                handleTimeChange(schedule, "startTime", e.target.value)
              }
              onFocus={() =>
                handleFocusChange(schedule, "startTimeFocused", true)
              }
              onBlur={() =>
                handleFocusChange(schedule, "startTimeFocused", false)
              }
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 5 }}
              error={
                !schedule.startTimeFocused && !!schedule.startTimeErrorMessage
              }
              helperText={
                !schedule.startTimeFocused ? schedule.startTimeErrorMessage : ""
              }
              sx={{
                mr: 2,
                mb: isMobile ? 1 : 0,
                width: isMobile ? "100%" : "auto",
              }}
            />
            <TextField
              label={intl.formatMessage({ id: "admin.dashboard.endTime" })}
              type="text"
              placeholder="HH:mm"
              value={schedule.endTime}
              onChange={(e) =>
                handleTimeChange(schedule, "endTime", e.target.value)
              }
              onFocus={() =>
                handleFocusChange(schedule, "endTimeFocused", true)
              }
              onBlur={() =>
                handleFocusChange(schedule, "endTimeFocused", false)
              }
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 5 }}
              error={!schedule.endTimeFocused && !!schedule.endTimeErrorMessage}
              helperText={
                !schedule.endTimeFocused ? schedule.endTimeErrorMessage : ""
              }
              sx={{
                mr: 2,
                mb: isMobile ? 1 : 0,
                width: isMobile ? "100%" : "auto",
              }}
            />
            <IconButton
              onClick={() => handleDeleteSchedule(schedule.id)}
              color="error"
              sx={{ alignSelf: isMobile ? "flex-end" : "center" }}
              disabled={schedule.attendees && schedule.attendees.length > 0}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
          {!schedule.isNew && (
            <Accordion
              expanded={selectedScheduleId === schedule.id}
              onChange={() => handleAccordionChange(schedule)}
              sx={{ mt: 1 }}
            >
              <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                <Typography>
                  <FormattedMessage id="admin.dashboard.attendees" />
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                <List>
                  {schedule.attendees &&
                    schedule.attendees.map((attendee, index) => {
                      return (
                        !attendee.unenrollDate && (
                          <ListItem key={attendee.id}>
                            <ListItemText
                              primary={`${index + 1}. ${attendee.firstName} ${
                                attendee.lastName
                              }`}
                            />
                          </ListItem>
                        )
                      );
                    })}
                </List>
                {schedule.attendees && schedule.attendees.length === 0 && (
                  <Typography color="textSecondary">
                    <FormattedMessage id="admin.dashboard.noAttendees" />
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          )}
        </Box>
      ))
    ) : (
      <Typography color="textSecondary">
        <FormattedMessage id="admin.dashboard.unavailable" />
      </Typography>
    );
  };

  return (
    <Box sx={{ flexGrow: 1, position: "relative", height: "100%" }}>
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <FormattedMessage id="admin.dashboard.scheduleManager" />
          </Typography>
        </Toolbar>
        <Tabs value={activeTab} onChange={handleTabChange} centered>
          <Tab
            label={<FormattedMessage id="admin.dashboard.weeklySchedules" />}
          />
          <Tab
            label={<FormattedMessage id="admin.dashboard.oneTimeSchedules" />}
          />
        </Tabs>
      </AppBar>
      <Box sx={{ height: "calc(100% - 112px)", overflowY: "auto", pb: 8 }}>
        <Paper sx={{ p: 2, m: 2 }}>
          {activeTab === 0 ? renderSchedules(true) : renderSchedules(false)}
        </Paper>
      </Box>
      <Box
        sx={{
          position: "sticky",
          bottom: -20,
          left: 0,
          right: 0,
          padding: 2,
          backgroundColor: "white",
          borderTop: 1,
          borderColor: "divider",
          display: "flex",
          justifyContent: "flex-end",
          zIndex: 9999,
        }}
      >
        <Button
          variant="contained"
          color="primary"
          onClick={handleSaveAllChanges}
          disabled={hasErrors}
          sx={{
            minWidth: isMobile ? "100%" : "auto",
          }}
        >
          <FormattedMessage id="admin.dashboard.saveAllChanges" />
        </Button>
      </Box>
    </Box>
  );
}
